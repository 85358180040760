import { KeycloakAuthService } from 'src/app/services/keycloak-auth.service';

export const environment = {
  production: false,
  environment: 'REC',
  apiURL: "https://p3saek3gh9.execute-api.eu-west-1.amazonaws.com/rec",
  awsRegion: "eu-west-1",
  googleClientId: "290638486407-sgr8jdjs5aaip3eqc2fc4q2pf80k7h8h.apps.googleusercontent.com",
  identityPoolId: "eu-west-1:ccf6c976-1937-4bf7-8ed5-b1da90f35c91",
  build: "155",
  commit: "f4933335f2f82a6ee1c4a09c0776dbf968457028",

  auth: {
    config: {
      config: {
        url: "https://keycloak-hom.dir.istefr.fr",
        realm: "vef",
        clientId: "SHADOK",
      },
      initOptions: {
        onLoad: "check-sso",
        flow: "standard",
        "enable-cors": true,
        pkceMethod: "S256",
        silentCheckSsoRedirectUri:
          "https://rec-shadok.dir.istefr.fr/assets/silent-check-sso.html",
        "public-client": true,
      },
    },
    loginRedirectUri: "https://rec-shadok.dir.istefr.fr/monitoring/prod/home",
    logoutRedirectUri: "https://rec-shadok.dir.istefr.fr/",
    provider: KeycloakAuthService,
    federatedSignInProvider: "keycloak-hom.dir.istefr.fr/realms/vef",
  }
};
